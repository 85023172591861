<template>
	<a-modal
		class="editPlatform"
		v-model="isshow"
		:title="`${isEdit ? '编辑' : '选择'}平台`"
		:width="640"
		@cancel="handleCancel"
		@ok="ok_handle"
		:footer="isEdit ? undefined : null">
		<a-tabs v-model="activeTab" :tab-position="'left'">
			<a-tab-pane key="2" tab="电商前台">
				<a-tree
					:show-line="true"
					:show-icon="true"
					:tree-data="treeData_2"
					:replaceFields="{ children: 'child', title: 'site' }"
					@select="clickTreeNode">
					<a-icon type="environment" slot="country" /> </a-tree
			></a-tab-pane>
			<a-tab-pane key="1" tab="电商后台">
				<a-tree
					:show-line="true"
					:show-icon="true"
					:tree-data="treeData_1"
					:replaceFields="{ children: 'child', title: 'site' }"
					@select="clickTreeNode">
					<a-icon type="environment" slot="country" /> </a-tree
			></a-tab-pane>
			<a-tab-pane key="5" tab="自定义平台">
				<a-tree
					:show-line="true"
					:show-icon="true"
					:tree-data="treeData_5"
					:replaceFields="{ children: 'child', title: 'site' }"
					@select="clickTreeNode">
					<a-icon type="environment" slot="country" />
					<template slot="child_title" slot-scope="{ id, site }"
						>{{ site }} <a-button size="small" type="link" @click.stop="delPlatform(id, site)">删除</a-button></template
					>
				</a-tree>
			</a-tab-pane>
		</a-tabs>
		<a-button type="primary" class="add_btn" @click="isShowAddPlatform = true">添加平台</a-button>
		<!-- 添加自定义平台 -->
		<a-modal v-model="isShowAddPlatform" :width="500" centered title="自定义平台" @ok="handleOk">
			<div class="middle_password_line" style="margin-top: 0px">
				<div class="middle_p_left">平台名称</div>
				<a-input placeholder="请输入平台名称（如亚马逊）" v-model="site_value" style="width: 440px" />
			</div>

			<div class="middle_password_line">
				<div class="middle_p_left">平台地址</div>
				<a-input placeholder="请输入平台地址，请以http://或https://开头" v-model="site_url" style="width: 440px" />
			</div>
			<div class="middle_password_line">
				<div class="middle_p_left">所属国家及地区</div>
				<a-select :showSearch="true" v-model="platform_country" @change="chooseCountry" placeholder="选择平台所属国家及地区" style="width: 440px">
					<a-select-option :value="item.country" v-for="item in countryList" :key="item.id">
						{{ item.country }}
					</a-select-option>
				</a-select>
			</div>
		</a-modal>
	</a-modal>
</template>
<script>
import moment from "moment";
import {
	department,
	chosmember,
	getmember,
	batchenvir,
	batchaa,
	environment_platform_list,
	delPlatfrom,
	user_platform,
	user_crepla,
} from "@/api/environment.js";
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			activeTab: "2",
			isshow: true,
			isShowAddPlatform: false,
			site_value: "",
			site_url: "",
			platform_country: null,
			countryId: "",
			countryList: [],
			auth_modalstatus: true,
			SHOW_PARENT,
			roleTitleNoe: true,
			roleNum: -1,
			bumenAll: [],
			bumenList: [],
			staffAll: [],
			columns: [
				{
					title: "username",
					dataIndex: "username",
				},
			],
			coko: [
				{
					title: "username",
					dataIndex: "username",
				},
				{
					title: "Action",
					dataIndex: "",
					key: "x",
					scopedSlots: { customRender: "action" },
				},
			],
			departmentsAll: [],
			dataAll: [],
			selectedRowKeys: [], // Check here to configure the default column
			selectedCheck: [],
			list: [],
			treeData_2: [],
			treeData_1: [],
			treeData_5: [],
		};
	},
	watch: {
		checkedKeys() {},
	},
	async mounted() {
		if (this.isEdit) this.activeTab = "5";

		this.getData();
		let res = await user_platform({});
		if (res.data.code == 200) {
			this.countryList = res.data.data;
		}
	},
	computed: {},
	methods: {
		async getData() {
			let { data } = await environment_platform_list({
				type: "all",
				pagesize: 100,
				page: 1,
				status: 0,
				prefix: "", //不传返回的site是【自定义平台】百度，传的话那么返回就是百度
			});
			if (data.code == 200) {
				this.list = data.data.list;
				this.treeData_2 = [];
				this.treeData_1 = [];
				this.treeData_5 = [];
				this.list.forEach((item, index) => {
					item.child.forEach(it => {
						it.site = it.country;
						it.key = it.platform_id + "-" + it.country_id + "-" + it.id;
						it.slots = { switcherIcon: "country" };
						if (item.type == 5) {
							it.scopedSlots = { title: "child_title" };
						}
					});
					item.key = item.id;
					// item.selectable = false;
					//type 2 电商前台   type 1 电商后台  type 5 自定义平台
					if (item.type == 1) {
						this.treeData_1.push(item);
					}
					if (item.type == 2) {
						this.treeData_2.push(item);
					}
					if (item.type == 5) {
						this.treeData_5.push(item);
					}
				});
				//   "list",
				//   this.list.filter((item) => item.type == 5)
				// );
			}
		},
		test() {},
		delPlatform(id, site) {
			let that = this;
			this.$confirm({
				title: `你确定删除属于“${site}”的这个自定义平台吗?`,
				onOk() {
					return delPlatfrom({ id }).then(res => {
						if (res.data.code == 200) {
							that.getData();
							that.$message.success("删除成功");
						}
					});
				},
				onCancel() {},
			});
		},
		clickTreeNode(selectedKeys, e) {
			e.node.onExpand();
			if (e.selectedNodes[0]) {
				const obj = e.selectedNodes[0].data.props;
				if ((!obj.child || !obj.child.length) && !this.isEdit) {
					if (this.activeTab == "2") {
						obj.platform_type = "电商前台";
					}
					if (this.activeTab == "1") {
						obj.platform_type = "电商后台";
					}
					if (this.activeTab == "5") {
						obj.platform_type = "自定义平台";
					}
					// if (this.isEdit) return;
					this.$emit("finish", obj);
				}
			}
		},

		handleCancel() {
			this.$emit("cancel");
		},
		async ok_handle() {
			if (this.isEdit) this.$emit("finish");
		},
		async handleOk() {
			if (!/^(http:|https:)/.test(this.site_url)) {
				this.$message.error("请输入包含http://或https://的完整网址");
				return;
			}
			let { data } = await user_crepla({
				site: this.site_value,
				site_url: this.site_url,
				country_id: this.countryId,
			});
			if (data.code == 200) {
				this.$message.success("添加成功");
				this.getData();
				this.activeTab = "5";
				// this.expandedKeys = [data.data.id];
				this.isShowAddPlatform = false;
			}
		},
		chooseCountry(value) {
			this.countryList.forEach(item => {
				if (item.country == value) {
					this.countryId = item.id;
				}
			});
		},
	},
};
</script>
<style scoped lang="less">
::v-deep .ant-tree-node-content-wrapper-normal .ant-tree-title {
	width: 260px;
	display: flex;
	justify-content: space-between;
}
/deep/ .ant-modal-content {
	min-height: 300px;
}

/deep/ .ant-modal-title {
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #2c354b;
	line-height: 25px;

	div {
		display: inline-block;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
	}
}
.editPlatform {
	/deep/ .ant-modal-body {
		position: relative;
		height: 400px;
		overflow-y: auto;
		padding-left: 30px;
		color: #374567;
	}
}

.add_btn {
	position: absolute;
	left: 40px;
	top: 184px;
}
// ::v-deep .ant-tabs-content,
// .ant-tabs-tabpane {
//   overflow-y: auto;
// }

.line_wrap {
	display: flex;
	margin-top: 20px;

	.line_left {
		width: 140px;
		text-align: right;
		line-height: 32px;
	}

	.line_right {
		width: 430px;
		margin-left: 10px;
	}

	.text-din {
		width: 70px;
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 32px;
		cursor: pointer;
	}

	.text-yuan {
		height: 32px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ff8e43;
		line-height: 32px;
		cursor: pointer;
	}
}

/deep/ .ant-tabs-tabpane-active {
	display: flex;
}

.pane-right {
	position: relative;
	width: 350px;
	height: 369px;
	border-radius: 2px;
	opacity: 0.75;
	border: 1px solid #dedfe2;
	margin-left: 40px;

	.piaop1 {
		position: absolute;
		top: -48px;
		width: 70px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 20px;
		cursor: pointer;
	}

	.piaop2 {
		position: absolute;
		top: -48px;
		right: 0;
		width: 56px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #4c84ff;
		line-height: 20px;
		cursor: pointer;
	}
}

.pane-left {
	width: 370px;
	height: 369px;
	border-radius: 2px;
	opacity: 0.75;
	border: 1px solid #dedfe2;

	.let-box {
		margin-top: 15px;
		// width: 370px;
		height: 38px;
		padding-left: 15px;
		height: 20px;
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #374567;
		line-height: 20px;
		cursor: pointer;
		position: relative;

		.anticon-right {
			margin-left: 266px;
			font-size: 12px;
			position: absolute;
			right: 18px;
		}
	}
}

/deep/ .ant-input-affix-wrapper .ant-input {
	height: 36px;
	line-height: 36px;
}

/deep/ .ant-table-thead tr th {
	height: 38px;
	background-color: #fff;
	border: none;
}

/deep/ .ant-tabs-bar {
	border: none;
}

/deep/ .ant-table-tbody tr td {
	height: 38px;
	border: none;
	background-color: #fff;
}

/deep/ .ant-table-thead {
	display: none;
}

/deep/ .pane-left .ant-table-placeholder {
	display: none;
}

/deep/ .pane-left .sec .ant-table-placeholder {
	display: block;
}

/deep/ .ant-table-placeholder {
	border: none;
}

/deep/ .ant-modal-content .ant-modal-footer {
	display: block;
}
</style>
